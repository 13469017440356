<template>
<!-- <div class="outer" ref="scroll"> -->
<!-- <div class="pulldown-wrapper">
            <div v-show="beforePullDown">
                <span>下拉刷新</span>
            </div>
            <div v-show="!beforePullDown">
                <div v-show="isPullingDown">
                    <span>加载中...</span>
                </div>
                <div v-show="!isPullingDown">
                    <span>刷新成功</span>
                </div>
            </div>
        </div> -->
<div class="container">
  <div class="top-box">
    <img class="background" src="/img/main/background.png" alt="">
    <div class="top" v-if="role == 4">{{ organization_name }}</div>
    <div class="top" v-if="role != 4">{{ name }}</div>
    <div class="bottom">
      <div class="phone" @click="toLogout">{{ username }} ></div>
      <template v-if="role == 4">
        <div class="guide" @click="ziping">机构自评手册</div>
        <img class="icon" src="/img/main/info.png">
      </template>
      <!-- <template v-else-if="(role == 6 && inspectType == 4)">
        <div class="guide" @click="toCreate">创建他评</div>
      </template> -->
    </div>
    <div><my-search @toSearch="toSearch"></my-search></div>
  </div>
  <div class="content-box">
    <van-tabs :ellipsis="false" color="#0083f5" v-model="activeName" @click="handleClick" title-active-color="#0083f5" line-width="1.875rem" line-height="0.25rem">
      <template v-if="inspectType == 1">
        <template v-if="role == 4">
          <van-tab title="未提交" name="0"></van-tab>
          <van-tab title="已提交" name="1"></van-tab>
          <van-tab title="已评审" name="2"></van-tab>
        </template>
        <template v-if="role != 4">
          <van-tab title="未评审" name="0"></van-tab>
          <van-tab title="评审中" name="1"></van-tab>
          <van-tab title="已评审" name="2"></van-tab>
        </template>
      </template>
      <template v-if="inspectType != 1">
        <van-tab title="评审中" name="0"></van-tab>
        <van-tab title="已评审" name="2"></van-tab>
      </template>
    </van-tabs>
    <swiper :options="swiperOption" :auto-update="true" :auto-destroy="true" ref="Swiper" style="width:100%;height:calc(100% - 41px);">
      <!--<template>
        <swiper-slide v-if="role == 4">
          <div class="inner" ref="scroll1">
            <div class="inner-box" v-if="list1.length > 0">
              <div class="single" v-for="(item, index) in list1" :key="index" @click="open(item, 1)" @touchstart="start(item,1)" @touchend="end">
                <div class="title" v-if="role == 3">{{ item.org_name }}</div>
                <div class="title" v-if="role == 4">{{ item.title }}</div>
                <div class="time" v-if="item.type == 1 && item.examine_status == 0">创建时间：{{ item.created_time }}</div>
                <div class="time" v-if="role != 4 && item.examine_status == 2">评审开始时间：{{ item.submit_date }}</div>
                <div class="time" v-if="item.examine_status == 1">评审结束时间：{{ item.examine_date }}</div>
                <div class="score">
                  <span v-if="role == 4">自评总分：{{ item.score }}分</span>
                  <span v-else>评审总分：{{ item.score }}分</span>
                  <span style="margin-left: 28px" v-if="role == 4">自评进度：{{ item.ratio }}</span>
                  <span style="margin-left: 28px;overflow:hidden;" v-else>标<img style="margin-top: 2px;" src="/img/main/star.png">通过：{{item.examine_stars}}项</span>
                </div>
              </div>
            </div>
            <div class="nodata-box" v-else>
              <img src="/img/main/nodata.png" alt="">
              <div class="nodata-desc">暂无内容~ </div>
            </div>
          </div>
        </swiper-slide>
      </template>-->
	  <template>
	    <swiper-slide v-if="inspectType==1">
	      <div class="inner" ref="scroll1">
	        <div class="inner-box" v-if="list1.length > 0">
	          <div class="single" v-for="(item, index) in list1" :key="index" @click="open(item, 1)" @touchstart="start(item,1)" @touchend="end">
	            <img class="single-type" src="/img/main/org.png" alt="" v-if="item.type == 1">
	            <img class="single-type" src="/img/main/city.png" alt="" v-else-if="item.type == 2">
	            <img class="single-type" src="/img/main/nation.png" alt="" v-else-if="item.type == 3">
				<div class="title" v-if="role == 6||role == 3||role == 2">{{ item.org_name }}</div>
	            <div class="title" v-if="role == 4">{{ item.title }}</div>
	            <div class="time" v-if="item.type == 1 && item.examine_status == 0&&role ==4">创建时间：{{ item.created_time }}</div>
				<div class="time" v-if="item.type == 1 && item.submit_date">提交时间：{{ item.submit_date }}</div>
				<div class="time" v-if="role != 4 && item.examine_status == 2">评审开始时间：{{ item.examine_start_time }}</div>
	            <div class="time" v-if="item.examine_status == 1">评审结束时间：{{ item.examine_date }}</div>
	            <div class="score">
	              <span v-if="role == 4">自评总分：{{ item.score }}分</span>
	              <span v-else>自评总分：{{ item.score }}分</span>
	              <span style="margin-left: 28px" v-if="role == 4">自评进度：{{ item.ratio }}</span>
	              <span style="margin-left: 28px;overflow:hidden;" v-else>标<img style="margin-top: 2px;" src="/img/main/star.png">通过：{{item.stars}}项</span>
				  <!--<span style="margin-left: 28px;overflow:hidden;" v-else>标<img style="margin-top: 2px;" src="/img/main/star.png">通过：{{item.examine_stars}}项</span>-->
	            </div>
	          </div>
	        </div>
	        <div class="nodata-box" v-else>
	          <img src="/img/main/nodata.png" alt="">
	          <div class="nodata-desc">暂无内容~ </div>
	        </div>
	      </div>
	    </swiper-slide>
	  </template>
      <swiper-slide>
        <div class="inner" ref="scroll2">
          <div class="inner-box" v-if="list2.length > 0">
            <div class="single" v-for="(item, index) in list2" :key="index" @click="open(item, 2)" @touchstart="start(item,2)" @touchend="end">
              <template >
                <img class="single-type" src="/img/main/org.png" alt="" v-if="item.type == 1">
                <img class="single-type" src="/img/main/city.png" alt="" v-else-if="item.type == 2">
                <img class="single-type" src="/img/main/nation.png" alt="" v-else-if="item.type == 3">
              </template>
              <div class="title" v-if="role == 6||role == 3||role == 2">{{ item.org_name }}</div>
              <div class="title" v-if="role == 4">{{ item.title }}</div>
              <div class="time" v-if="item.type == 1 && item.submit_status == 0">创建时间：{{ item.created_time }}</div>
              <div class="time" v-if="item.type == 1 && item.submit_status == 1&&role == 4">提交时间：{{ item.submit_date }}</div>
              <div class="time" v-if="role != 4 && item.examine_status == 2">评审开始时间：{{ item.examine_start_time }}</div>
              <div class="time" v-if="item.examine_status == 1">评审结束时间：{{ item.examine_date }}</div>
              <!-- 机构 -->
              <template v-if="role == 4">
                <div class="score" v-if="role == 4">
                  <span v-if="role == 4">自评总分：{{ item.score }}分</span>
                  <span v-else>评审总分：{{ item.examine_score }}分</span>
                  <!-- <span style="margin-left: 28px">标<img src="/img/main/star.png">通过：0项</span> -->
                </div>
              </template>
              <!-- 督导 -->
              <template v-else>
                <div class="score">
                  <div class="score-box">
                    <div class="score-box__inner1">评审总分</div>
                    <div class="score-box__inner2" v-if="item.type == 1">{{item.examine_score}}分</div>
                    <div class="score-box__inner2" v-else>{{item.score}}分</div>
                  </div>
                  <div class="score-box">
                    <div class="score-box__inner1">评审进度</div>
                    <div class="score-box__inner2" v-if="item.type == 1">{{item.examine_ratio}}</div>
                    <div class="score-box__inner2" v-else>{{item.ratio}}</div>
                  </div>
                  <div class="score-box">
                    <div class="score-box__inner1">
                      <div>标<img src="/img/main/star.png">通过</div>
                    </div>
                    <div class="score-box__inner2" v-if="item.type == 1">{{item.examine_stars}}项</div>
                    <div class="score-box__inner2" v-else>{{item.stars}}项</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="nodata-box" v-else>
            <img src="/img/main/nodata.png" alt="">
            <div class="nodata-desc">暂无内容~ </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="inner" ref="scroll3">
          <div class="inner-box" v-if="list3.length > 0">
            <div class="single" v-for="(item, index) in list3" :key="index" @click="open(item, 3)">
              <img class="single-type" src="/img/main/org.png" alt="" v-if="item.type == 1">
              <img class="single-type" src="/img/main/city.png" alt="" v-else-if="item.type == 2">
              <img class="single-type" src="/img/main/nation.png" alt="" v-else-if="item.type == 3">
              <div class="title" v-if="role == 6|| role == 3||role == 2">{{ item.org_name }}</div>
              <div class="title" v-if="role == 4">{{ item.title }}</div>
              <div class="time" v-if="item.type == 1 && item.examine_status == 0">创建时间：{{ item.created_time }}</div>
              <div class="time" v-if="role != 4 && item.examine_status == 2">评审开始时间：{{ item.examine_start_time }}</div>
              <div class="time" v-if="item.examine_status == 1 && role == 4">评审时间：{{ item.examine_date }}</div>
              <div class="time" v-if="item.examine_status == 1 && (role == 3 || role == 6)">评审结束时间：{{ item.examine_date }}</div>
			  <div class="time" v-if="item.examine_status == 1 && role == 2">评审结束时间：{{ item.examine_date }}</div>
              <!-- <template v-if="role == 4">
                <div class="score">
                  <span>已评审完成</span>
                </div>
              </template> v-else-->
              <template >
                <div class="score">
                  <div class="score-box" v-if="item.type == 1">
                    <div class="score-box__inner1">评审等级</div>
                    <div class="score-box__inner2" v-if="item.examine_results == 0 || item.examine_results == 1" style="color:#C40410">D(需要重大改进)</div>
                    <div class="score-box__inner2" v-else-if="item.examine_results == 2" style="color:#F68904">C(不合格)</div>
                    <div class="score-box__inner2" v-else-if="item.examine_results == 3" style="color:#63C81D">B(合格)</div>
                    <div class="score-box__inner2" v-else-if="item.examine_results == 4" style="color:#0094F5">A(优秀)</div>
                  </div>
                  <div class="score-box" v-else>
                    <div class="score-box__inner1">评审等级</div>
                    <div class="score-box__inner2" v-if="item.results == 0 || item.results == 1" style="color:#C40410">D(需要重大改进)</div>
                    <div class="score-box__inner2" v-else-if="item.results == 2" style="color:#F68904">C(不合格)</div>
                    <div class="score-box__inner2" v-else-if="item.results == 3" style="color:#63C81D">B(合格)</div>
                    <div class="score-box__inner2" v-else-if="item.results == 4" style="color:#0094F5">A(优秀)</div>
                  </div>
                  <!-- <div class="score-box">
                    <div class="score-box__inner1">评审进度</div>
                    <div class="score-box__inner2" v-if="item.type == 1">{{item.examine_ratio}}</div>
                    <div class="score-box__inner2" v-else>{{item.ratio}}</div>
                  </div> -->
                  <div class="score-box">
                    <div class="score-box__inner1">评审总分</div>
                    <div class="score-box__inner2" v-if="item.type == 1">{{item.examine_score}}分</div>
                    <div class="score-box__inner2" v-else>{{item.score}}分</div>
                  </div>
                  <div class="score-box">
                    <div class="score-box__inner1">
                      <div>标<img src="/img/main/star.png">通过</div>
                    </div>
                    <div class="score-box__inner2" v-if="item.type == 1">{{item.examine_stars}}项</div>
                    <div class="score-box__inner2" v-else>{{item.stars}}项</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="nodata-box" v-else>
            <img src="/img/main/nodata.png" alt="">
            <div class="nodata-desc">暂无内容~ </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <div class="dialog_tip" v-if="show_dialog_box">
  <div class="dialog_box">
  	  <div @click="go_detail">查看自评</div>
  	  <div @click="start_examine">质量评审</div>
  	  <div  @click="close_dialog" class="quxiao_btn">取消</div>
  </div>
  </div>
  
  <div class="btn-box" style="flex-direction: column" v-if="role == 4 && activeName == 0&&list1.length<1">
    <el-button class="login-btn" @click="addCheck" :disabled="!canCheck" type="primary" v-throttle>创建评估
    </el-button>
    <!-- <div class="footer">北京京学科技发展集团有限公司提供技术支持</div> -->
  </div>
  <!-- <div class="model-box" v-if="showModel">
            <div class="select-box">
                <div class="select-box__top">是否继续自评？</div>
                <div class="select-box__bottom">
                    <div class="select-box__bottom--left">重新自测</div>
                    <div class="select-box__bottom--mid"></div>
                    <div class="select-box__bottom--right">继续</div>
                </div>
            </div>
        </div>
        <div class="model" v-if="showModel"></div> -->

  
</div>
<!-- </div> -->
</template>


<script>
import {
  Swiper,
  SwiperSlide,
  directive
} from "vue-awesome-swiper"
// import BScroll from "@better-scroll/core"
// import PullDown from "@better-scroll/pull-down"
// import PullUp from '@better-scroll/pull-up'
import "swiper/css/swiper.css"

// BScroll.use(PullDown)
// BScroll.use(PullUp)

// const TIME_BOUNCE = 800
// const REQUEST_TIME = 1000
// const THRESHOLD = 70
// const STOP = 56
// let STEP = 0
import mySearch from '@/components/search'
export default {
  name: "Main",
  data() {
    const that = this;
    return {
      searchForm:{},
		  show_dialog_box:false,
      tabLeft: 'two-tab',
      showSupervisor: false, // 展示督导页面
      showCommon: false, // 展示机构页面
      role: '',
      list1: [], //机构的未提交  
      list2: [], // 机构已提交  省级评审中
      list3: [], //机构已评审 省级已评审
      inspectType: '', //4省级他评 2市级他评 3国家级督证 1机构自评
      pageType: 1,
      currentPage: 1,
      limit: 50,
      name: '',
      username: '',
      showModel: false,
      canCheck: true,
      beforePullDown: true,
      isPullingDown: false,
      activeName: 0,
      nowIndex: 0,
      organization_name: '',
      swiperOption: {
        // 设定初始化时slide的索引
        initialSlide: 0,
        direction: "horizontal",
        on: {
          slideChange: function () {
            //这个是当swiper被改变是的回调函数，可以拿到当前索引
            that.activeName = this.activeIndex.toString();
            console.log(that.activeName)
            sessionStorage.setItem('swiper', that.activeName)
          }
        }
      },
    };
  },
  computed: {},
  watch: {
    '$route': {
      handler: function (to, from) {
        // 如果你需要在特定条件下刷新页面，例如路由参数发生变化
        if (to.params.path !== from.params.path) {
          // 使用 location.reload() 刷新页面
          // window.location.reload();
        }
      },
      // 设置为深度监听
      deep: true
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    mySearch
  },
  async mounted() {
    this.name = localStorage.getItem('name')
    this.organization_name = localStorage.getItem('organization_name')
    this.username = localStorage.getItem('username')
    this.role = localStorage.getItem('role')
    this.inspectType = this.$route.query.type;
    if (this.inspectType == 1) {
      this.tabLeft = 'three-tab'
    }
    console.log(this.role, this.inspectType)
    const loading = this.$loading({
      lock: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    loading.close();

    if (this.inspectType == 1) {//机构自评
      if (this.role == 4) {
        await this.init(1)
        await this.init(2)
        await this.init(3)
      } else {
        await this.init(2)
        await this.init(4)
        await this.init(3)
      }
    } else if (this.inspectType != 1) {
      await this.init(4)
      await this.init(3)
    }

    let tabIndex = sessionStorage.getItem('swiper') || 0;
    if (tabIndex) {
      this.$refs.Swiper.$swiper.slideTo(tabIndex, 300, false);
      this.$refs.Swiper.swiperOptions.initialSlide = tabIndex;
      this.activeName = tabIndex.toString();
      sessionStorage.setItem('swiper', '');
    }
    // this.initBscroll()
  },
  actived() {

  },
  methods: {
    // initBscroll() {
    //     this.bscroll = new BScroll(this.$refs.scroll, {
    //         scrollY: true,
    //         bounceTime: TIME_BOUNCE,
    //         useTransition: false,
    //         pullDownRefresh: {
    //             threshold: THRESHOLD,
    //             stop: STOP
    //         },
    //         pullUpLoad: true
    //     })
    //     this.bscroll.on('pullingDown', this.pullingDownHandler)
    //     this.bscroll.on('scroll', this.scrollHandler)
    //     this.bscroll.on('scrollEnd', e => {
    //         console.log('scrollEnd')
    //     })
    //     this.bscroll.on('pullingUp', this.pullingUpHandler)
    // },
    // scrollHandler(pos) {
    //     console.log(pos.y)
    // },
    // async pullingDownHandler(type) {
    //     console.log('trigger pullDown')
    //     this.beforePullDown = false
    //     this.isPullingDown = true
    //     STEP += 1

    //     await this.requestData()

    //     this.isPullingDown = false
    //     this.finishPullDown()
    // },
    // async finishPullDown() {
    //     this.bscroll.finishPullDown()
    //     setTimeout(() => {
    //         this.beforePullDown = true
    //         this.bscroll.refresh()
    //     }, TIME_BOUNCE + 100)
    // },
    // async requestData() {
    //     try {
    //         const newData = await this.ajaxGet(/* url */)
    //         this.dataList = newData.concat(this.dataList)
    //     } catch (err) {
    //         // handle err
    //         console.log(err)
    //     }
    // },
    // async pullingUpHandler() {
    //     this.isPullUpLoad = true

    //     await this.requestData()

    //     this.bscroll.finishPullUp()
    //     this.bscroll.refresh()
    //     this.isPullUpLoad = false
    // },
    // ajaxGet(/* url */) {
    //     return new Promise(resolve => {
    //         setTimeout(() => {
    //             console.log(1)
    //             resolve(20)
    //         }, 1000)
    //     })
    // },
    async toSearch(info){
      console.log(info)
      this.searchForm = info
      if (this.inspectType == 1) {
        if (this.role == 4) {
          await this.init(1)
          await this.init(2)
          await this.init(3)
        } else {
          await this.init(2)
          await this.init(4)
          await this.init(3)
        }
      } else if (this.inspectType != 1) {
        await this.init(4)
        await this.init(3)
      }
    },
    async init(type) {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/index",
        data: {
          inspect_type: this.inspectType,
          page: this.currentPage,
          limit: this.limit,
          type: type,
          ...this.searchForm
        }
      });
      if (res.code == 0) {
        console.log(res.data)
        if (this.role == 4) { //机构
          if (type == 1) {
            this.list1 = res.data.list
          } else if (type == 2) {
            this.list2 = res.data.list
          } else {
            this.list3 = res.data.list
          }
        } else if (this.role == 3) { //市级
          if (type == 4) {
            this.list2 = res.data.list
          } else if (type == 3) {
            this.list3 = res.data.list
          }
        } else {
          if (type == 1 || type == 2) {
            this.list1 = res.data.list
          } else if (type == 2 || type == 4) {
            this.list2 = res.data.list
          } else {
            this.list3 = res.data.list
          }
        }
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    handleScroll() {
      var scrollTop = this.$refs.scroll1.scrollTop; //滑入屏幕上方的高度
      var windowHeight = this.$refs.scroll1.clientHeight; //能看到的页面的高度
      var scrollHeight = this.$refs.scroll1.scrollHeight; //监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      let total = scrollTop + windowHeight
      if (total == scrollHeight) {
        console.log("到底了")
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.activeName = tab.toString();
      sessionStorage.setItem('swiper', tab)
      this.$refs.Swiper.$swiper.slideTo(tab, 300, false);
      this.$refs.Swiper.swiperOptions.initialSlide = tab;
    },
    async addCheck() {
      let res = await this.$api({
        method: 'get',
        url: '/api/inspect/create'
      })
      this.$router.push({
        path: '/list',
        query: {
          id: res.data.inspect_id,
          type: 1,
          is_new: 1
        }
      })
      this.canCheck = false
    },
    toCreate() {
      this.$router.push('/create')
    },
    toHelp() {
      this.$router.push('/help')
    },
    taping() {
      window.open('https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/files/%E4%B8%93%E5%AE%B6%E7%9D%A3%E5%AF%BC%E4%BB%96%E8%AF%84-%E5%B7%A5%E4%BD%9C%E6%89%8B%E5%86%8C7.5.pdf')
    },
    ziping() {
      window.open('https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/files/%E6%89%98%E8%82%B2%E6%9C%BA%E6%9E%84%E8%87%AA%E8%AF%84-%E5%B7%A5%E4%BD%9C%E6%89%8B%E5%86%8C7.5.pdf')
    },
    toLogout() {
      this.$router.push('/user/logout')
    },
    showList(type) {
      this.$router.push({
        path: '/main',
        query: {
          type
        }
      })
    },
	close_dialog(){
		this.show_dialog_box=false;
	},
	go_detail(){
		this.show_dialog_box=false;
		this.$router.push({
		  path: '/list',
		  query: {
		    id: this.check_item.inspect_id,
		    type: this.check_type,
		    is_new: 0,
		  }
		})
	},
	start_examine(){
		this.show_dialog_box=false;
		this.$api({
		  method: 'get',
		  url: '/api/inspect/start_examine',
		  data: {
		    inspect_id: this.check_item.inspect_id
		  }
		}) 
		this.$router.push({
		  path: '/list',
		  query: {
		    id: this.check_item.inspect_id,
		    type: this.check_type,
		    is_new: 0
		  }
		})
	},
    async open(item, type) {
      console.log(item)
      if (item.examine_status == 1) {
        this.$router.push({
          path: '/report',
          query: {
            inspect_id: item.inspect_id,
          }
        })
        return
      } 
      if (this.role == 2 && type == 1) {
        if(item.examine_status==0){//评审中
			this.show_dialog_box=true;
			this.check_item=item;
			this.check_type=type;
			return 
		}
      }
      this.$router.push({
        path: '/list',
        query: {
          id: item.inspect_id,
          type: type,
          is_new: 0
        }
      })
      // this.showModel = true
      // this.$confirm('是否继续自评？', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //     this.$message({
      //         type: 'success',
      //         message: '删除成功!'
      //     });
      // }).catch(() => {
      //     this.$message({
      //         type: 'info',
      //         message: '已取消删除'
      //     });
      // });
    },
    start(item, type) {
      if(item.type != 3 && (this.role == 1 || this.role == 2)) {
        return;
      }
      if ((item.type == 1 && type == 1) || (item.type == 2 && type == 2) || (item.type == 3 && type == 2)) {
        clearTimeout(this.loop); //再次清空定时器，防止重复注册定时器
        this.loop = setTimeout(() => {
          this.$Dialog.confirm({
            message: `删除后不可恢复数据，确定删除吗？`,
            confirmButtonColor: '#0083F5',
            confirmButtonText: '确定',
          }).then(async () => {
            let res = await this.$api({
              method: 'get',
              url: '/api/inspect/delete',
              data: {
                inspect_id: item.inspect_id,
              }
            })
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '删除成功'
              });
              if ((item.type == 1 && type == 1)) {
                this.init(1)
              } else if ((item.type == 2 && type == 2)) {
                this.init(4)
              }
            } else {
              this.$message(res.msg)
            }
          }).catch(() => {

          });
        }, 1000);
      }
    },
    end() {
      clearTimeout(this.loop); //清空定时器，防止重复注册定时器
    },
  },
};
</script>

<style lang="scss">
	.dialog_tip{
	 position: fixed; height: 100%; bottom:0; width: 100%;z-index: 999; background: rgba(0,0,0,.5);
	.dialog_box{ 
		position:absolute; height: auto; bottom:0; width: 100%;  background: #eee; 
		div{ height: 50px; line-height: 50px; text-align: center; background: #fff; margin-bottom:1px;}
		.quxiao_btn{ margin-top:4px;}
	}
}
.container {
  height: 100%;

  .top-box {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 125px;
    // background-image: url('/img/main/background.png');
    // background-size: 100% 125px;

    .background {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 125px;
    }

    .top {
      margin: 25px 0 0 15px;
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 27px;
    }
    .search{
      position: absolute;
      right: 10px;
      top: 25px;
      // transform: translateY(-50%);
    }
    .bottom {
      z-index: 99;
      margin: 10px 12px 0 15px;
      display: flex;
      align-items: center;

      .phone {
        margin-right: auto;
        font-size: 14px;
        font-weight: 400;
        color: #FFF;
        line-height: 20px;
      }

      .guide {
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        background-clip: text;
      }

      .icon {
        margin-left: 3px;
        width: 13px;
        height: 13px;
      }
    }
  }

  .content-box {
    position: absolute;
    top: 100px;
    z-index: 99;
    height: calc(100% - 100px);
    width: 100%;
    overflow-y: scroll;
    background: #F7F8FA;
    border-radius: 10px 10px 0px 0px;

    .content-btn {
      width: 345px;
      height: 107px;
      margin: 10px 15px 0;
    }

    .content-btn:first {
      margin-top: 20px;
    }

    // .swiper-container {
    //     overflow-y: scroll;
    // }

    .inner {
      position: relative;
      overflow-y: scroll;
      height: 100%;
      padding: 5px 15px 15px;
      padding-bottom: 80px;

      .inner-box {
        .single {
          position: relative;
          padding: 15px;
          margin-top: 10px;
          width: auto;
          background: #FFFFFF;
          box-shadow: 0px 1px 5px 0px rgba(245, 245, 245, 1);
          border-radius: 6px;

          .single-type {
            width: 55px;
            height: 26px;
            position: absolute;
            top: 10px;
            right: -4px;
          }

          .title {
            text-align: left;
            font-size: 17px;
            font-weight: 500;
            color: #333333;
            line-height: 17px;
          }

          .time {
            text-align: left;
            margin-top: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            line-height: 14px;
          }

          .score {
            display: flex;
            text-align: left;
            align-items: center;
            justify-content: space-between;
            margin-top: 19px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 14px;

            .score-box {
              display: flex;
              flex-direction: column;

              .score-box__inner1 {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }

              .score-box__inner2 {
                min-height: 14px;
                margin-top: 14px;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                line-height: 14px;
              }
            }

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .nodata-box {
        margin-top: 50%;
        transform: translateY(-50%);
        img {
          width: 196px;
          height: 119px;
          margin: 0 auto;
          display: block;
        }

        .nodata-desc {
          font-size: 14px;
          font-weight: 400;
          color: #888888;
          line-height: 21px;
          text-align: center;
        }
      }
    }
  }

  .btn-box {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 73px;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .login-btn {
      width: 323px;
      font-size: 19px;
      color: #ffffff;
    }
  }

  .model-box {
    z-index: 2001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    .select-box {
      width: 240px;
      height: 133px;
      background: #FFFFFF;
      border-radius: 6px;
      display: inline-block;
      vertical-align: middle;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      margin: auto;

      .select-box__top {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #222222;
        height: 88px;
        border-bottom: 1px solid #EEEEEE;
      }

      .select-box__bottom {
        display: flex;
        align-items: center;
        justify-content: center;

        .select-box__bottom--left {
          width: calc((100% - 1px)/2);
          font-size: 17px;
          font-weight: 400;
          color: #666666;
          line-height: 45px;
        }

        .select-box__bottom--mid {
          width: 1px;
          height: 45px;
          background: #EEEEEE;
        }

        .select-box__bottom--right {
          width: calc((100% - 1px)/2);
          font-size: 17px;
          font-weight: 400;
          color: #0083F5;
          line-height: 45px;
        }
      }
    }
  }

  .model {
    z-index: 2000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
  }
}
</style>

<style lang="scss">
div.three-tab {
  ::v-deep .el-form-item {
    margin-bottom: 16px;
  }

  ::v-deep .el-form-item__content {
    display: flex;
  }
}

div.two-tab {

  ::v-deep .el-form-item {
    margin-bottom: 16px;
  }

  ::v-deep .el-form-item__content {
    display: flex;
  }
}
</style>
<style lang="scss" scoped>

</style>